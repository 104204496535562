import { createWebHistory, createRouter } from 'vue-router';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/account/login'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/account/register'),
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/layout.vue'),
    children: [
      {
        path: '/TravelBooking',
        name: 'TravelBooking',
        component: () => import('@/views/TravelBooking/index'),
      },
      {
        path: '/activateService',
        name: 'activateService',
        component: () => import('../views/index/activateService'),
      },
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index'),
      },
      {
        path: '/PermissionManagement',
        name: 'PermissionManagement',
        component: () => import('@/views/PermissionManagement/index'),
      },
      {
        path: '/SupplierManagement',
        name: 'SupplierManagement',
        component: () => import('@/views/SupplierManagement/index'),
      },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/manage/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'manage',
        component: () => import('@/views/child_app/manage.vue'),
      },
      {
        // 严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/tripay/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'tripay',
        component: () => import('@/views/child_app/tripay.vue'),
      },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/finance/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'finance',
        component: () => import('@/views/child_app/finance.vue'),
      },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/finance/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'finance',
        component: () => import('@/views/child_app/finance.vue'),
      },
      {
        path: '/CooperationInformation',
        name: ' ',
        component: () => import('@/views/CooperationInformation/index'),
      },
      // {
      //   path: "/",
      //   redirect: "/TravelBooking",
      // },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/scgy/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'scgy',
        component: () => import('@/views/child_app/scgy.vue'),
      },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/settlement/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'settlement',
        component: () => import('@/views/child_app/settlement.vue'),
      },
      {
        path: '/',
        redirect: '/TravelBooking',
      },
      {
        path: '/:path(.*)*',
        redirect: '/404',
      },
      {
        path: '/404',
        name: 'notFound',
        component: () => import('@/views/error/404'),
      },
      {
        path: '/parentAccount',
        name: 'parentAccount',
        component: () => import('@/views/parentAccount/index'),
      },
      {
        path: '/addInvoice',
        name: 'addInvoice',
        component: () => import('@/views/invoice/addInvoice'),
      },
      {
        path: '/invoiceManage',
        name: 'invoiceManage',
        component: () => import('@/views/invoice/index'),
      },
      {
        path: '/setInvoiceDimension',
        name: 'setInvoiceDimension',
        component: () => import('@/views/invoice/invoiceTitle/setInvoiceDimension'),
      },
      {
        path: '/rechargeRecords',
        name: 'rechargeRecords',
        component: () => import('@/views/rechargeRecords'),
      },
      {
        path: '/biIframe',
        name: 'biIframe',
        component: () => import('@/views/child_app/biIframe'),
      },
      {
        path: '/reward-travel',
        name: 'RewardTravel',
        component: () => import('@/views/RewardTravel/index'),
      },
    ],
  },
  {
    path: '/fxxt',
    name: 'FXSLayout',
    component: () => import('@/views/fxxt/layout.vue'),
    children: [
      {
        path: '/fxxt/index',
        name: 'home',
        component: () => import('@/views/fxxt/index/index.vue'),
      },
      {
        path: '/fxxt/TravelBooking',
        name: 'FxTravelBooking',
        component: () => import('@/views/TravelBooking/index'),
      },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/fxxt/manage/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'FxManage',
        component: () => import('@/views/child_app/manage.vue'),
      },
      {
        // 非严格匹配，/my-page/* 都指向 MyPage 页面
        path: '/fxxt/order/:page*', // vue-router@4.x path的写法为：'/my-page/:page*'
        name: 'order',
        component: () => import('@/views/child_app/order.vue'),
      },
      {
        path: '/fxxt/:path(.*)*',
        redirect: '/fxxt/index',
      },
      {
        path: '/fxxt/404',
        name: 'not',
        component: () => import('@/views/error/404'),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes, // `routes: routes` 的缩写
});

export default router;
