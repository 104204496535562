import { defineStore } from 'pinia';
import tool from '@/common/tool.js';
import { addLinkTag } from '@/utils/index';

export const useEnterpriseStore = defineStore('enterpriseConfigInfo', {
  state: () => {
    return {
      enterpriseInfo: {
        agentAppName: '',
        agentLogoDist: '',
        agentName: '',
        agentPlatId: '',
        apiUrl: '',
        hostUrl: '',
        integralLogoUrl: '',
        platId: '',
        servicePhone: '',
      }, // 企业信息
    };
  },
  getters: {
    showDefault() {
      return !this.enterpriseInfo.agentPlatId;
    },
    pc_login_logo() {
      return `https://image.ceekee.com/tmc/${this.enterpriseInfo.agentLogoDist}/logo_pclogin.png`;
    },
    pc_menu_logo() {
      return `https://image.ceekee.com/tmc/${this.enterpriseInfo.agentLogoDist}/logo_pchome.png`;
    },
  },
  actions: {
    updateEnterpriseConfigInfo(data) {
      this.enterpriseInfo = data;
      // 动态添加浏览器图标
      addLinkTag(
        `./favicon${this.enterpriseInfo.agentPlatId ? '_' + this.enterpriseInfo.agentPlatId : ''}.ico`,
        'icon',
      );
      // https://clv.gdcts.com/
    },
  },
  persist: true,
});
