import router from './router';
// import NProgress from "nprogress"; // Progress 进度条
// import "nprogress/nprogress.css"; // Progress 进度条样式
import { ElMessage } from 'element-plus';
import { useLocalStorage } from '@vueuse/core';
import tool from '@/common/tool';
import { platformApi } from '@/request/api/platform';
import request from '@/request/request.js';
import menuList from './common/menuList';
import menuListId from './common/menuListId';
import addMenu from './addMenu';
import fxsMenuList from '@/common/fxs-menuList';
import { GetSpecifiedGlobalConfig } from '@/request/api/common';

const whiteList = [
  '/login',
  '/register',
  '/404',
  '/403',
  '/manage#/downloadMonthReport',
  '/manage/#/downloadMonthReport',
];
const RouterList = useLocalStorage('RouterList');
const menuIdData = useLocalStorage('menuIdData');
let isShowPage = [];
let init = false;

// router.afterEach((to, from) => {
//   NProgress.done(); // 结束Progress
// });

const dealHashPath = (to) => {
  // 去除？
  let url = to.hash.split('?')[0] || '';

  //  #/organizationManage 这边特殊处理 暂时写死 因为没用?传值无法判断路由
  if (url.indexOf('#/organizationManage') > -1) {
    url = '#/organizationManage/0';
  }

  return `/${to.name}/${url}`;
};

const promiseAllRequest = () => {
  const promise1 = request({
    apiModule: platformApi.listUserMenu,
  });
  const promise2 = GetSpecifiedGlobalConfig({ ConfigType: 188 });
  return Promise.all([promise1, promise2]);
};

const gotoRouter = async (to, next) => {
  try {
    let [res, res2] = await promiseAllRequest();
    if (tool.checkRequestResult(res)) {
      const resData = JSON.parse(JSON.stringify(res.data));
      // 如果没有开通【国内酒店开通节省奖励功能】的企业，不展示出行奖励
      if (!(res2.Data && res2.Data.IsValid && res2.Data.ConfigValue === '1')) {
        (resData.menuList || []).forEach((item) => {
          if (item.menuId === 4) {
            item.childMenuIds = item.childMenuIds.filter((it) => it !== 86);
          }
        });
      }
      tool.setLocalStorage('_Customer_Name_', resData.userName);
      menuIdData.value = JSON.stringify(resData.menuList || []);
      const localMenuList = addMenu(menuList, resData.menuList);

      RouterList.value = JSON.stringify(localMenuList || []);

      isShowPage = addIsShowPage(resData);
      init = true;

      addmenuListNew();

      if (to.path == '/') {
        next('/TravelBooking');
      } else {
        next({ ...to, replace: true });
      }
    } else {
      ElMessage.error(res.message);
      localStorage.clear();
      window.location.href = window.location.protocol + '//' + window.location.host + '/login';
    }
  } catch (error) {
    ElMessage.error(error.message);
  }
};

const loadFxsRouter = async (to, next) => {
  try {
    const customerInfo = tool.getLocalStorage('_User_Info_');
    const showMenu = customerInfo['IsManager'] ? fxsMenuList : [{ menuId: 2 }];
    const localMenuList = addMenu(fxsMenuList, showMenu);
    RouterList.value = JSON.stringify(localMenuList);
    init = true;
    if (to.path == '/') {
      next('/fxxt/TravelBooking');
    } else {
      next({ ...to, replace: true });
    }
  } catch (error) {
    ElMessage.error(error.message);
  }
};

const addIsShowPage = (data) => {
  let arr = [];
  data.menuList.forEach((item) => {
    if (!item.childMenuIds.length) {
      arr.push(item.menuId);
    } else {
      item.childMenuIds.forEach((it) => {
        arr.push(it);
      });
    }
  });
  return menuListId.filter((i) => arr.includes(i.menuId)).map((i) => i.url);
};

const addmenuListNew = async () => {
  let res = await request({
    apiModule: platformApi.listUserMenuNew,
  });
  if (tool.checkRequestResult(res)) {
    let datas = res.data || [];

    let result = {
      name: '数据魔方',
      type: 'module',
      icon: '',
      url: '',
      menus: [],
      hidden: false,
    };
    let list = setMenuList(datas, 0, []);
    result.menus = list;

    RouterList.value = JSON.stringify(JSON.parse(RouterList.value).concat(result));
  }
};

const setMenuList = (rootList, id, list) => {
  rootList.forEach((item) => {
    if (item.parentId == id) {
      list.push(setMenuItem(item));
    }
  });

  list.forEach((i) => {
    i.subs = [];
    setMenuList(rootList, i.menuId, i.subs);
  });

  return list;
};

const setMenuItem = (item) => {
  let mItem = {
    hidden: false,
    icon: item.icon,
    name: item.menuName,
    url: item.pageLink || item.menuName,
    type: 'menu',
    routerType: 'iframe',
    pId: item.parentId,
    menuId: item.id,
  };

  return mItem;
};

// 判断是不是第一次进应用
let firstLoaded = true;

export const permissionHandle = async (to, from, next) => {
  let MemberId = (tool.getLocalStorage('_User_Info_') || {}).MemberId || '';
  let Token = (tool.getLocalStorage('_User_Info_') || {}).Token || '';
  let platId = tool.getLocalStorage('PlatId') || '';
  if (platId == '1005') {
    if (Token && MemberId) {
      if (to.path.includes('404')) {
        next('/fxxt/index');
      }
      if (to.path !== '/login') {
        tool.setLocalStorage('showBreadcrumb', false);
        if (init) {
          next();
        } else {
          // 加载分销商后台菜单
          loadFxsRouter(to, next);
        }
      } else {
        next('/');
      }
    } else {
      if (to.path !== '/login') {
        next('/login');
      } else {
        next();
      }
    }
    return;
  }

  // NProgress.start();
  let routerPath = '';
  if (to.hash) {
    routerPath = dealHashPath(to);
  } else {
    routerPath = to.path;
  }
  if (whiteList.indexOf(routerPath) !== -1) {
    next();
  } else {
    if (Token && MemberId) {
      if (to.path !== '/login') {
        tool.setLocalStorage('showBreadcrumb', false);
        if (init) {
          // 根据hash判断是否是跳转微应用
          if (isShowPage.indexOf(routerPath) > -1) {
            next();
          } else {
            next();
          }
        } else {
          gotoRouter(to, next);
        }
      } else {
        next('/');
      }
    } else {
      if (to.path !== '/login') {
        next('/login');
      } else {
        next();
      }
    }
  }
};
