<template>
  <router-view></router-view>
</template>

<script>
import { getUUId } from './request/auth.js';
import tool from './common/tool.js';
export default {
  name: 'App',

  setup() {
    let sessionId = getUUId();
    tool.setLocalStorage('sessionId', sessionId);
  },
};
</script>

<style lang="less">
@import './styles/style.less';
</style>
<style>
@import '@/assets/icon/iconfont.css';
</style>
