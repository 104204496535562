export default [
  {
    name: '首页',
    icon: 'tripwise-manage icon-shouye',
    url: '/index',
    type: 'menu',
    menuId: 1,
  },
  {
    name: '服务开通',
    icon: 'tripwise-manage icon-part_fill',
    url: '/activateService',
    type: 'menu',
    menuId: 100,
  },
  {
    name: '差旅预订',
    icon: 'tripwise-manage icon-yuding',
    url: '/TravelBooking',
    type: 'menu',
    menuId: 2,
  },
  {
    name: '行政',
    icon: '',
    url: '/admin',
    type: 'module',
    menus: [
      {
        name: '组织管理',
        icon: 'tripwise-manage icon-renyuan',
        url: 'orgManage',
        type: 'menu',
        menuId: 3,
        subs: [
          {
            name: '组织架构',
            icon: '',
            url: '/manage/#/organizationManage/0',
            type: 'menu',
            routerType: 'micro-app',
            menuId: 19,
            pId: 3,
          },
          {
            name: '审批设置',
            icon: '',
            url: '/manage/#/newManage/approvalSetting',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 20,
            pId: 3,
          },
        ],
      },
      {
        name: '消费规则',
        icon: 'tripwise-manage icon-danju01',
        url: 'consumptionRuler',
        type: 'menu',
        menuId: 4,
        subs: [
          {
            name: '差标管理',
            icon: '',
            url: '/manage/#/newManage/travelStandardManage',
            type: 'menu',
            routerType: 'micro-app',
            menuId: 24,
            pId: 4,
          },
          {
            name: '奖励出行',
            icon: '',
            url: '/reward-travel',
            type: 'menu',
            menuId: 86,
            pId: 4,
          },
          {
            name: '商务卡',
            icon: '',
            url: '/tripay/#/newManage/bussinessCard',
            type: 'menu',
            routerType: 'micro-app',
            menuId: 25,
            pId: 4,
            tagId: 121,
          },
        ],
      },
      {
        name: '权限管理',
        icon: 'tripwise-manage icon-quanxian',
        url: '/PermissionManagement',
        type: 'menu',
        menuId: 5,
      },
      {
        name: '供应商管理',
        icon: 'tripwise-manage icon-bao',
        url: '/SupplierManagement',
        type: 'menu',
        menuId: 6,
      },
    ],
  },
  {
    name: '财务',
    icon: '',
    url: '/financial',
    type: 'module',
    menus: [
      {
        name: '结算管理',
        icon: 'tripwise-manage icon-danju02',
        url: 'settlementManage',
        type: 'menu',
        menuId: 8,
        subs: [
          // {
          //   name: '账单数据',
          //   icon: '',
          //   url: '/manage/#/newManage/settlementManage',
          //   routerType: 'micro-app',
          //   type: 'menu',
          //   menuId: 64,
          //   pId: 8,
          // },
          {
            name: '账单管理',
            icon: '',
            url: '/settlement/#/bill',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 64,
            pId: 8,
          },
          {
            name: '发票历史',
            icon: '',
            url: '/settlement/#/invoice-history',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 80,
            pId: 8,
          },
          {
            name: '开票设置',
            icon: '',
            url: '/settlement/#/invoice-settings',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 66,
            pId: 8,
          },
          {
            name: '结算规则',
            icon: '',
            url: '/settlement/#/rules',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 81,
            pId: 8,
          },
        ],
      },
      {
        name: '补贴管理',
        icon: 'tripwise-manage icon-jinbi01',
        url: '/allowancesManage',
        type: 'menu',
        menuId: 71,
        subs: [
          {
            name: '差旅补贴',
            icon: '',
            url: '/manage/#/newManage/travelSubsidyManage',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 9,
          },
          {
            name: '私车公用',
            icon: '',
            url: '/scgy/#/ruleConfiguration',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 73,
          },
        ],
      },
      // {
      //   name: "差补管理",
      //   icon: "tripwise-manage icon-jinbi01",
      //   url: "/manage/#/newManage/travelSubsidyManage",
      //   routerType: "micro-app",
      //   type: "menu",
      //   menuId: 9,
      //   tagId: 32,
      // },
      {
        name: '预算管理',
        icon: 'tripwise-manage icon-jinbi02',
        url: '/manage/#/newManage/budgetManage',
        routerType: 'micro-app',
        type: 'menu',
        menuId: 10,
        tagId: 33,
      },
      {
        name: '费用管理',
        icon: 'tripwise-manage icon-danju04',
        url: '/expensesReimbursement',
        type: 'menu',
        menuId: 11,
        subs: [
          {
            name: '基础信息',
            icon: '',
            url: '/manage/#/newManage/basicInfo',
            routerType: 'micro-app',
            type: 'menu',
            menuId: 41,
            pId: 11,
            tagId: 131,
          },
          {
            name: '单据信息',
            icon: '',
            url: '/manage/#/newManage/documentManage',
            type: 'menu',
            routerType: 'micro-app',
            menuId: 42,
            pId: 11,
            tagId: 131,
          },
        ],
      },
      {
        name: '财务映射',
        icon: 'tripwise-manage icon-ka',
        url: '/financeMapping',
        type: 'menu',
        menuId: 12,
        subs: [
          {
            name: '映射管理',
            icon: '',
            url: '/finance/#/newManage/mapManage',
            type: 'menu',
            routerType: 'micro-app',
            menuId: 51,
            pId: 12,
          },
          {
            name: '凭证管理',
            icon: '',
            url: '/finance/#/newManage/certificateManage',
            type: 'menu',
            routerType: 'micro-app',
            menuId: 52,
            pId: 12,
          },
        ],
      },
      // {
      //   name: '子母公司',
      //   icon: 'tripwise-manage icon-part_fill',
      //   url: '/parentAccount',
      //   routerType: '',
      //   type: 'menu',
      //   menuId: 67,
      //   tagId: 33,
      // },
      {
        name: '子母公司',
        icon: 'tripwise-manage icon-part_fill',
        url: '/settlement/#/parent-company',
        routerType: 'micro-app',
        type: 'menu',
        menuId: 67,
        tagId: 33,
      },
    ],
  },
  {
    name: '公共',
    icon: '',
    url: '/public',
    type: 'module',
    menus: [
      // {
      //   name: '数据洞察',
      //   icon: 'tripwise-manage icon-danju03',
      //   url: '/manage/#/manageStatistics',
      //   routerType: 'micro-app',
      //   type: 'menu',
      //   menuId: 13,
      //   tagId: 21,
      // },
      {
        name: '合作信息',
        icon: 'tripwise-manage icon-fangwu',
        url: '/manage/#/newManage/cooperationInfo',
        routerType: 'micro-app',
        type: 'menu',
        menuId: 14,
      },
      {
        name: '企业福利',
        icon: 'tripwise-manage icon-fuli',
        url: '/manage/#/newManage/companyWelfare',
        routerType: 'micro-app',
        type: 'menu',
        menuId: 15,
      },
    ],
  },
];
