import { createApp } from 'vue';
import App from './App.vue';
import ElementPlus from 'element-plus';
import { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import tool from './common/tool.js';
import request from './request/request.js';
import { b2bApi } from 'tripwise_interface_b2b';
import microApp from '@micro-zoe/micro-app';
import { permissionHandle } from './permission';
import locale from 'element-plus/dist/locale/zh-cn.mjs';
import * as dd from 'dingtalk-jsapi';
import mitt from 'mitt';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import { config, agentUrlList } from './config/config';
import { dispatchEventStorage } from '@/common/watchLocalStorage';
import apiConfigs from './common/apiconfig';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createPinia } from 'pinia';
import { useEnterpriseStore } from '@/store/modules/enterprise.js';
import { updateEnterpriseInfo } from './utils/initPlatConfig';

const pinia = createPinia();
const bus = mitt();
const app = createApp(App);

app.config.globalProperties.$bus = bus;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

pinia.use(piniaPluginPersistedstate);
app.use(ElementPlus, { locale });
app.use(ElementPlus);
app.use(router);
app.use(dispatchEventStorage);
app.use(pinia);
app.mount('#app');

microApp.start({
  'disable-memory-router': true, // 关闭虚拟路由系统
  'disable-patch-request': true, // 关闭对子应用请求的拦截
});
let plantId = '';
let needUrlJump = false;

router.beforeEach((to, from, next) => {
  let locationhref = window.location.href;
  let agentIndex = agentUrlList.findIndex((a) => locationhref.indexOf(a) >= 0);
  if (agentIndex >= 0) {
    let enterpriseId = tool.global_getQueryString('enterpriseId') || 0;
    if (enterpriseId) {
      updateEnterpriseInfo(enterpriseId);
    }
    init(to, from, next);
  } else {
    updateEnterpriseInfo().then((enterpriseInfo) => {
      init(to, from, next);
    });
  }
});

const loginHandle = (param, to, from, next) => {
  request({ apiModule: b2bApi.login, data: param }).then((res) => {
    if (res.Code == '0000') {
      setCustomers(res, to, from, next);
    } else {
      ElMessage.success(res.Msg);
      setTimeout(() => {
        window.location.href = window.location.protocol + '//' + window.location.host + '/login';
      }, 500);
    }
  });
};

const init = (to, from, next) => {
  let locationhref = window.location.href;
  let ebookingIndex = config.distributionWebsite.findIndex((a) => locationhref.indexOf(a) >= 0);
  if (ebookingIndex > -1) {
    tool.setLocalStorage('PlatId', '1005');
  }
  if (locationhref.indexOf('memberId=') > 0) {
    localStorage.clear();
    let isManageParam = tool.getQueryString('isManage') || 0;
    let enterpriseName = tool.getQueryString('EnterpriseName');
    let enterpriseId = tool.getQueryString('enterpriseId') || 0;
    let memberId = tool.getQueryString('memberId').replace(/\s/g, '+');
    let token = tool.getQueryString('token');
    let isManage = isManageParam;
    localStorage.removeItem('apiUrl');
    localStorage.removeItem('Customers');
    var login = {
      MemberId: memberId,
      Token: token,
      IsManager: isManage,
      EnterpriseName: enterpriseName,
    };
    if (enterpriseId) {
      tool.setLocalStorage('EnterpriseId', parseInt(enterpriseId));
    } else {
      localStorage.removeItem('EnterpriseId');
    }

    tool.setLocalStorage('_User_Info_', login);
    setTimeout(() => {
      if (isManage == 1) {
        window.location.href = window.location.protocol + '//' + window.location.host + '/index';
      } else {
        window.location.href = window.location.protocol + '//' + window.location.host + '/TravelBooking';
      }
    }, 500);
  } else if (locationhref.indexOf('userId=') > 0) {
    const params = tool.getParams(location.href);
    const urlArr = location.href.split('?');
    const targetUrl = urlArr[0];
    let queryString = '';
    for (let [key, value] of Object.entries(params)) {
      if (key !== 'userId' && key !== 'token') {
        queryString += `&${key}=${value}`;
      }
    }
    queryString = queryString ? queryString.replace('&', '?') : '';
    const redirectUrl = targetUrl + queryString;
    localStorage.clear();
    const login = {
      MemberId: params.userId ? decodeURIComponent(params.userId).replace(/\s/g, '+') : '',
      Token: params.token || '',
    };
    tool.setLocalStorage('_User_Info_', login);
    window.location.href = redirectUrl;
    setTimeout(() => {
      window.location.reload();
    }, 500);
  } else if (locationhref.indexOf('.com/#/') >= 0) {
    window.location.href = window.location.protocol + '//' + window.location.host + '/TravelBooking';
  } else if (locationhref.indexOf('auth_code=') > 0) {
    localStorage.clear();
    plantId = '1033';
    needUrlJump = true;
    tool.setLocalStorage('PlatId', '1033');
    let param = {
      type: 6,
      TmpAuthCode: tool.getQueryString('auth_code'),
      PlatId: '1033',
    };
    ElMessage.success('登录中。。。');
    request({ apiModule: b2bApi.login, data: param })
      .then((res) => {
        if (tool.checkRequestResult(res)) {
          res.IsServiceQiYeWX = 1;
          setCustomers(res, to, from, next);
        } else {
          ElMessage.error(res.Msg);
        }
      })
      .catch((err) => {
        ElMessage.error(err);
      });
  } else if (locationhref.indexOf('feishu') >= 0 && locationhref.indexOf('code') >= 0) {
    plantId = '1040';
    needUrlJump = true;
    tool.setLocalStorage('PlatId', '1040');
    let code = tool.getQueryString('code') || '';
    let params = {
      type: 5,
      TmpAuthCode: code,
      PlatId: '1040',
    };
    loginHandle(params, to, from, next);
  } else if (
    locationhref.indexOf('EnterpriseId') >= 0 ||
    locationhref.indexOf('corpid') >= 0 ||
    locationhref.indexOf('code') >= 0
  ) {
    //钉钉登录处理
    plantId = '1028';
    needUrlJump = true;
    let code = tool.getQueryString('code') || '';
    let corpId = tool.getQueryString('corpid');
    tool.setLocalStorage('PlatId', '1028');
    if (!(dd.env.platform == 'notInDingTalk')) {
      let EnterpriseId = tool.getQueryString('EnterpriseId');
      localStorage.removeItem('EnterpriseId');
      localStorage.removeItem('CorpId');
      if (EnterpriseId) {
        let apiconfig = apiConfigs.find((x) => x.enterpriseId == EnterpriseId);
        if (apiconfig) {
          localStorage.setItem('apiUrl', apiconfig.apiUrl);
          tool.setLocalStorage('EnterpriseId', EnterpriseId);
          let param = {
            type: 4,
            // EnterpriseId: EnterpriseId,
            CorpId: apiconfig.cropId,
            PlatId: apiconfig.plantId,
            RefId: 153,
          };
          ddLogin(param, apiconfig.cropId);
        }
      } else if (corpId) {
        try {
          localStorage.setItem('apiUrl', 'https://prodapi.ceekee.com/');
          let param = {
            type: 5,
            PlatId: '1028',
            CorpId: corpId,
            RefId: 153,
          };
          ddLogin(param, corpId);
        } catch (e) {
          alert(e);
        }
      }
    } else if (code) {
      localStorage.setItem('apiUrl', 'https://prodapi.ceekee.com/');
      tool.setLocalStorage('PlatId', '1028');
      tool.setLocalStorage('refId', '153');
      let param = {
        type: 6,
        PlatId: '1028',
        TmpAuthCode: code,
        RefId: 153,
      };
      loginHandle(param, to, from, next);
    }
    function ddLogin(param, corpId) {
      if (corpId) {
        dd.ready(() => {
          ElMessage.success('登录中...');
          dd.runtime.permission.requestAuthCode({
            corpId: corpId, // 企业id
            onSuccess: (info) => {
              code = info.code; //通过该免登授权码可以获取用户身份
              param.TmpAuthCode = code;
              loginHandle(param, to, from, next);
            },
            onFail: (err) => {
              alert(JSON.stringify(err));
            },
          });
        });
        dd.error((error) => {
          alert(`dd error: ${JSON.stringify(error)}`);
        });
      } else {
        loginHandle({}, to, from, next);
      }
    }
  } else if (window.location.href.indexOf('TmpAuthCode=') > 0) {
    localStorage.clear();
    needUrlJump = true;
    let code = tool.getQueryString('TmpAuthCode');
    let refId = tool.getQueryString('RefId') || '';
    let processInstanceId = tool.getQueryString('ProcessInstanceId') || '';
    let productType = tool.getQueryString('Page') || '0';
    let toPage = tool.getQueryString('location') || '';
    let toOrderDetailSerialNo = tool.getQueryString('orderNo') || '';
    // tool.setLocalStorage("RefId", "1033");
    tool.setLocalStorage('RefId', refId || '');
    tool.setLocalStorage('processInstanceId', processInstanceId || '');
    tool.setLocalStorage('Page', productType || '');
    tool.setLocalStorage('location', toPage || '');
    tool.setLocalStorage('orderNo', toOrderDetailSerialNo || '');
    if (code) {
      let param = {
        type: 3,
        TmpAuthCode: code,
      };
      loginHandle(param, to, from, next);
    }
  } else if (locationhref.indexOf('env=dmm') > 0) {
    localStorage.clear();
    let paramsStr = locationhref.substring(locationhref.indexOf('=') + 1);
    let isManageParam = paramsStr.split('&isManage=');
    let params = isManageParam[0].split('&token=');
    let memberId = unescape(params[0]).replace(/\s/g, '+');
    let env = paramsStr.split('&env=')[1];
    let token = unescape(params[1]);
    let isManage = isManageParam[1].split('&env=')[0];
    localStorage.removeItem('apiUrl');
    localStorage.removeItem('Customers');
    var login = {
      MemberId: memberId,
      Token: token,
      IsManager: isManage,
      Env: env,
    };
    tool.setLocalStorage('_User_Info_', login);
    window.location.href = locationhref.substring(0, locationhref.indexOf('?'));
  } else {
    permissionHandle(to, from, next);
  }
};

const setCustomers = (res, to, from, next) => {
  if (res.Customers && res.Customers.length > 1) {
    localStorage.setItem('Customers', JSON.stringify(res.Customers));
  }
  let customer = {
    MemberId: res.Customers[0].MemberId,
    Token: res.Customers[0].Token,
    IsServiceQiYeWX: res.IsServiceQiYeWX || 0,
    IsManager: res.Customers[0].IsManager,
  };
  if (res.Customers.length == 1) {
    localStorage.setItem('Customers', []);
  }
  tool.setLocalStorage('EnterpriseId', res.Customers[0].EnterpriseId);
  tool.setLocalStorage('_User_Info_', customer);
  if (needUrlJump) {
    needUrlJump = false;
    if (res.Customers[0].IsManager) {
      window.location.href = window.location.protocol + '//' + window.location.host + '/index';
    } else {
      window.location.href = window.location.protocol + '//' + window.location.host + '/TravelBooking';
    }
  } else {
    permissionHandle(to, from, next);
  }
};
