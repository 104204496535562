export default [
  {
    name: '首页',
    icon: 'tripwise-manage icon-shouye',
    url: '/fxxt/index',
    type: 'menu',
    menuId: 1,
    hiddenEnterpriseId: [],
  },
  {
    name: '资源预订',
    icon: 'tripwise-manage icon-yuding',
    url: '/fxxt/TravelBooking',
    type: 'menu',
    menuId: 2,
    hiddenEnterpriseId: [746728],
  },
  {
    name: '订单列表',
    icon: 'tripwise-manage icon-danju04',
    url: '/fxxt/order/#/hotelOrderManage/orderList',
    routerType: 'micro-app',
    type: 'menu',
    menuId: 3,
    hiddenEnterpriseId: [],
  },
  {
    name: '结算管理',
    icon: 'tripwise-manage icon-danju02',
    url: '/fxxt/manage/#/settlementManage',
    routerType: 'micro-app',
    type: 'menu',
    menuId: 4,
    hiddenEnterpriseId: [746728],
  },
];
