export default function (meuns, data) {
  meuns.forEach((meun) => {
    if (meun.type == 'menu') {
      meun.hidden = !data.find((item) => {
        return item.menuId == meun.menuId;
      });
    } else {
      let temp = meun.menus.find((tMeun) => {
        return data.find((item) => {
          return item.menuId == tMeun.menuId;
        });
      });
      meun.hidden = !temp;
      if (temp) {
        meun.menus.forEach((item1) => {
          let temp1 = data.find((item) => {
            return item.menuId == item1.menuId;
          });
          item1.hidden = !temp1;
          if (item1.subs && temp1) {
            item1.subs.forEach((sMeun) => {
              sMeun.hidden = !temp1.childMenuIds.find((item) => {
                return item == sMeun.menuId;
              });
            });
          }
        });
      }
    }
  });
  //行政过滤完成
  return meuns;
}
