export default [
  {
    url: '/index',
    menuId: 1,
  },
  {
    url: '/TravelBooking',
    menuId: 2,
  },
  {
    url: '/manage/#/organizationManage/0',
    menuId: 19,
  },
  {
    url: '/manage/#/newManage/approvalSetting',
    menuId: 20,
  },
  {
    url: '/manage/#/newManage/travelStandardManage',
    menuId: 24,
  },
  {
    url: '/tripay/#/newManage/bussinessCard',
    menuId: 25,
    pId: 4,
    tagId: 121,
  },
  {
    url: '/PermissionManagement',
    menuId: 5,
  },
  {
    url: '/SupplierManagement',
    menuId: 6,
  },
  {
    url: '/manage/#/settlementManage',
    pId: 8,
    menuId: 64,
  },
  {
    url: '/addInvoice',
    pId: 8,
    menuId: 66,
  },
  {
    url: '/manage/#/newManage/travelSubsidyManage',
    menuId: 9,
    pId: 0,
    tagId: 32,
  },
  {
    url: '/manage/#/newManage/budgetManage',
    menuId: 10,
    pId: 0,
    tagId: 33,
  },
  {
    url: '/manage/#/newManage/basicInfo',
    menuId: 41,
    pId: 11,
    tagId: 131,
  },
  {
    url: '/manage/#/newManage/documentManage',
    menuId: 42,
    pId: 11,
    tagId: 131,
  },
  {
    url: '/finance/#/newManage/mapManage',
    menuId: 51,
  },
  {
    url: '/finance/#/newManage/certificateManage',
    menuId: 52,
    pId: 12,
    tagId: 111,
  },
  {
    url: '/manage/#/manageStatistics',
    menuId: 13,
    pId: 0,
    tagId: 21,
  },
  {
    url: '/manage/#/newManage/cooperationInfo',
    menuId: 14,
  },
  {
    url: '/manage/#/newManage/companyWelfare',
    menuId: 15,
  },
  {
    url: '/invoiceManage',
    menuId: 67,
  },
];
