export default [
  {
    enterpriseId: 26720, //迈为
    apiUrl: 'https://maiweiapi.ceekee.com/',
    webUrl: 'http://maiwei.tripwise.cn/',
    plantId: 1028,
    mwebUrl: 'https://newmaiwei.ceekee.com/',
    cropId: 'ding4fd7fb7f38562533',
  },
  {
    enterpriseId: 41225, //明喆
    apiUrl: 'https://mingzhewuyeapi.ceekee.com/',
    webUrl: 'http://mingzhewuye.tripwise.cn/',
    plantId: 1028,
    mwebUrl: 'https://mingzhewuye.ceekee.com/',
    cropId: 'ding1f63da81964e815f',
  },
  {
    enterpriseId: 41327, //海平面
    apiUrl: 'https://haipingmianapi.ceekee.com/',
    webUrl: 'http://haipingmian.tripwise.cn/',
    plantId: 1028,
    mwebUrl: 'https://haipingmian.ceekee.com/',
    cropId: 'ding44f7998aa828f2a535c2f4657eb6378f',
  },
  {
    //汇联易渠道
    enterpriseId: 0,
    apiUrl: 'https://api.ceekeeapi.com/',
    webUrl: '',
    mwebUrl: 'https://distribution.ceekee.com/',
    plantId: 1035,
    refId: 154,
    cropId: '',
  },
  {
    //浩天渠道
    enterpriseId: 0,
    apiUrl: 'https://api.ceekeeapi.com/',
    webUrl: '',
    mwebUrl: 'https://distribution.ceekee.com/',
    plantId: 1035,
    refId: 152,
    cropId: '',
  },
  {
    //钉钉第三方配置
    enterpriseId: 0,
    apiUrl: 'https://api.ceekeeapi.com/',
    webUrl: '',
    mwebUrl: 'https://m.ceekee.com/',
    plantId: 1028,
    cropId: '',
    type: 'thirdService',
  },
];
